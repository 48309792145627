.slide-container {
  width: 80%;
  margin-top: 30px;
  margin: auto;
}

.carousel.carousel-slider {
  margin: auto !important;
  background-color: #1D538F !important;
}

.review-container {
  width: 60%;
  margin: auto;
  margin-top: 20px;
  border-radius: 55px;
  /*border: 10px solid rgba(255, 255, 255, 0.8);*/
  background-color: rgba(41, 132, 218, 0.475);
}

.review-content {
  margin: auto;
  width: 70%;
  padding: 20px 0px;
}

.review-form {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-evenly;
  height: 350px;
}

.rating {
  margin: 10px
}

.review-form textarea {
  width: 90%;
  height: 100px;
}

.review {
  background-image: url(/public/index\ card.png);
  background-repeat: repeat;
  background-size: contain;
  background-color: rgb(0 0 0 / 10%);
  display: flex;
  flex-direction: column;
  align-items: baseline;
  border: 2px groove rgb(35 55 75 / 90%);
  border-radius: 10px;
  margin: 16px 0px;
  padding: 10px;
}

.each-slide-effect>div {
  display: flex;
  background-size: contain;
  background-position: 50% 0%;
  background-repeat: no-repeat;
  height: 600px;
}

.each-slide-effect span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}

@media (min-width: 320px) and (max-width: 999px) {
  .slide-container {
    width: 90%;
  }

  .review-content {
    width: 90%;
    padding: 5px 0px;
  }

  .review-container {
    width: 90%;
  }

  .carousel-wrapper{
    max-width: 100%;
  }
  
  .carousel {
    height: 30vh;
  }

  .each-slide-effect {
    height: 200px;
  }
}